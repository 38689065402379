import { SpeiseResult } from '../speise'
import { runningInBrowser } from '../utils'
import { withPrefix } from 'gatsby'

export default (url: string) => {
  let parser: Worker

  if (runningInBrowser) {
    parser = new Worker(withPrefix('/parser.bundle.js'))
  }
  return new Promise<SpeiseResult>(resolve => {
    const listener = (event: MessageEvent) => {
      parser.removeEventListener('message', listener)

      const result: SpeiseResult = event.data
      console.log(result)
      if (result.error) {
        throw new Error(result.error)
      }

      resolve(result)
    }

    parser.addEventListener('message', listener, false)

    parser.postMessage(url)
  })
}
