import styled from '@emotion/styled'
import theme from '../theme/theme'
import color from 'color'

export const BigInput = styled.input({
  border: 0,
  padding: 18,
  fontSize: '1.3rem',
  fontWeight: 600,
  color: theme.dark,
  width: '100%',
  boxShadow: `inset 0px -2px 5px ${color(theme.shadow).alpha(0.05)}`,

  ':focus': {
    boxShadow: `inset 0px 1px 5px ${color(theme.shadow).alpha(0.15)}`,
  },
})
