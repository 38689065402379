import React from 'react'
import {
  addAction,
  extractImageData,
  getQueryVariable,
  getUniqueFilename,
} from '../utils'
import { ActionType, Speise, SpeiseResult } from '../speise'
import { navigate } from '@reach/router'
import { ImportImagePicker } from '../components/import-image-picker'
import { ImportSpeiseForm } from '../components/import-speise-form'
import parseSpeise from '../parser/speise-parser'

interface LocalProps {
  readonly location: { search: string }
}

interface LocalState {
  speise: Speise
  bildURLs: string[]
}

class ImportPage extends React.Component<LocalProps, LocalState> {
  state = {
    speise: null,
    bildURLs: [],
  }

  componentDidMount() {
    const url = getQueryVariable(
      this.props.location.search.substring(1),
      'text'
    )

    parseSpeise(url)
      .then((result: SpeiseResult) => {
        if (result.speise && result.speise.titel && result.speise.bild) {
          this.addSpeiseCreateAction(result.speise)
          navigate('/aktionsliste')
        } else {
          this.setState({
            speise: result.speise,
            bildURLs: result.bildURLs ? result.bildURLs : [],
          })
        }
      })
      .catch(console.error)
  }

  render() {
    const { speise, bildURLs }: LocalState = this.state

    if (speise && speise.titel && bildURLs.length) {
      return (
        <ImportImagePicker
          title={speise.titel}
          imgURLs={bildURLs}
          onSubmitSpeise={this.onSubmitSpeise}
        />
      )
    }

    if (speise && speise.bild && !speise.titel) {
      return (
        <ImportSpeiseForm
          onSubmitSpeise={this.onSubmitSpeise}
          imgData={speise.bild}
        />
      )
    }

    return <React.Fragment>Lade...</React.Fragment>
  }

  private addSpeiseCreateAction = (speise: Speise): void => {
    const filename = getUniqueFilename(speise.titel)
    const image = extractImageData(speise.bild)
    addAction({
      speise,
      path: `speisen/${filename}.md`,
      imgPath: `speisen/images/${filename}.${image.extension}`,
      type: ActionType.CREATE,
    })
  }

  private onSubmitSpeise = (title: string, imgData: string) => {
    const { speise } = this.state

    speise.titel = title
    speise.bild = imgData

    this.addSpeiseCreateAction(speise)
    navigate('/aktionsliste')
  }
}

export default ImportPage
