import React, { Component } from 'react'
import styled from '@emotion/styled'
import theme from '../theme/theme'
import { BigInput } from '../styles/big-input'
import { BigButton } from '../styles/big-button'
import { downloadImage } from '../utils'
import { css } from '@emotion/core'

interface Props {
  title: string
  imgURLs: string[]
  onSubmitSpeise: (title: string, imgData: string) => void
}

interface State {
  title: string
  img: string
}

const Container = styled.div({
  flexDirection: 'column',
  backgroundColor: theme.light,
})

const List = styled.ul({
  listStyle: 'none',
  margin: 0,
  flex: 1,
  padding: '6px',
})

const Item = styled.li({
  border: `3px solid ${theme.light}`,
})

const selected = css({
  border: `3px solid ${theme.primary}`,
})

const Image = styled.img({
  border: `3px solid ${theme.light}`,
})

export class ImportImagePicker extends Component<Props, State> {
  state = {
    title: '',
    img: null,
  }
  componentWillMount() {
    const { title } = this.props
    this.setState({
      title,
    })
  }
  render() {
    const { imgURLs } = this.props
    const { title, img } = this.state
    return (
      <Container>
        <BigInput
          value={title}
          onChange={this.onInputTitle}
          placeholder="Titel"
        />
        <List>
          {imgURLs.map((url: string, index: number) => (
            <Item key={index} css={url === img ? selected : 'xcv'}>
              <Image src={url} key={index} onClick={this.onImageClick} />
            </Item>
          ))}
        </List>
        <BigButton onClick={this.onButtonClick}>Speichern</BigButton>
      </Container>
    )
  }

  private onImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
    const imgElement: HTMLImageElement = event.currentTarget

    this.setState({
      img: imgElement.src,
    })
  }

  private onInputTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const title: string = event.currentTarget.value

    this.setState({
      title,
    })
  }

  private onButtonClick = () => {
    const { onSubmitSpeise } = this.props
    const { title, img } = this.state

    downloadImage(img).then((imgData: string) => {
      onSubmitSpeise(title, imgData)
    })
  }
}
