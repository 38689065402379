import styled from '@emotion/styled'
import theme from '../theme/theme'

export const BigButton = styled.button({
  padding: '18px',
  textTransform: 'uppercase',
  border: 0,
  fontWeight: 600,
  backgroundColor: theme.primary,
  color: theme.light,
  width: '100%',
})
