import React, { Component } from 'react'
import styled from '@emotion/styled'
import theme from '../theme/theme'
import { BigInput } from '../styles/big-input'
import { BigButton } from '../styles/big-button'

interface Props {
  onSubmitSpeise: (title: string, imgData: string) => void
  imgData?: string
}

interface State {
  title: string
  imgData: string
}

const Container = styled.div({
  flexDirection: 'column',
  backgroundColor: theme.light,
  display: 'flex',
  alignItems: 'stretch',
})

const Image = styled.img({
  flex: 1,
  objectFit: 'cover',
  objectPosition: 'center center',
  margin: '1px 0',
})

export class ImportSpeiseForm extends Component<Props, State> {
  state = {
    title: '',
    imgData: null,
  }
  componentWillMount() {
    const { imgData } = this.props
    this.setState({
      imgData,
    })
  }
  render() {
    const { title, imgData } = this.state
    return (
      <Container>
        <BigInput
          value={title}
          onChange={this.onInputTitle}
          placeholder="Titel"
        />
        <Image src={imgData} />
        <BigButton onClick={this.onButtonClick}>Speichern</BigButton>
      </Container>
    )
  }

  private onButtonClick = () => {
    const { onSubmitSpeise } = this.props
    const { title, imgData } = this.state

    onSubmitSpeise(title, imgData)
  }

  private onInputTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const title: string = event.currentTarget.value

    this.setState({
      title,
    })
  }
}
